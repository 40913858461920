import type { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { SelectorSlider as SelectorSliderSchema } from '@amplience/content-types/typings/c-selector-slider';
import { useMediaMatch } from '@storefront/lib-global/hooks/useMediaMatch';
import { ArrowCircleLeft, ArrowCircleRight } from '@storefront/lib-global/icons';
import { FlexibleTextPartial } from '@storefront/lib-global/partials/flexibleText';
import { LinkPartial } from '@storefront/lib-global/partials/link';
import {
  TypographyStyles,
  TypographyStylesType,
  colours,
  media,
  slideToRightArrowsAnimation,
  spacing,
} from '@storefront/lib-global/stylings';
import { getLocalizedValue } from '@storefront/lib-global/utils/transformers';

export type SelectorSliderProps = SelectorSliderSchema;

const S = {
  SwiperWrapper: styled.div`
    display: flex;

    margin: 0 auto;
    max-width: 320px;
    padding: 0;
    padding-bottom: 20px;

    @media ${media.greaterThan('xx')} {
      padding-bottom: 43px;
    }
    button {
      background: ${colours.WHITE};
      border: none;
      margin: 0;
      padding: 0;
      &:disabled {
        cursor: not-allowed;
        opacity: 0.3;
      }
      & svg {
        height: 18.8px;
        width: 18.8px;

        @media ${media.greaterThan('lg')} {
          height: 25px;
          width: 25px;
        }
      }
    }
    @media ${media.greaterThan('lg')} {
      gap: 10px;
      max-width: 1024px;
    }
  `,
  Header: styled(FlexibleTextPartial)`
    text-align: center;
  `,
  SliderWrapper: styled.div``,
  SliderItem: styled.div`
    align-items: center;
    background: ${colours.WHITE};
    border: 1px solid ${colours.GREY};
    box-sizing: border-box;
    display: flex;
    height: 48px;
    margin: 0;
    padding: ${spacing.XXS} 10px;

    ${TypographyStyles.Body.Small.Regular}

    width: 76px;
    @media ${media.greaterThan('lg')} {
      height: 36px;
      padding: ${spacing.XXXXS} 10px;
      width: 80px;
    }
    a {
      color: ${colours.BLACK};

      text-align: center;
      text-decoration: none;
      width: 100%;
    }
    &:hover,
    &:focus {
      background: ${colours.LIGHT_BLUE};
      border: 1px solid ${colours.NAVY_BLUE};
      font-weight: 600;
    }
  `,

  Swiper: styled(Swiper)<{ $isDesktop: boolean; $sliderLength: number }>`
    .swiper {
      margin: 0;
      padding: 0;
      width: 245px;
      @media ${media.greaterThan('lg')} {
        width: 980px;
      }
    }
    .swiper-wrapper {
      display: flex;
      gap: 6px;
      justify-content: ${({ $isDesktop, $sliderLength }) => {
        if (($isDesktop && $sliderLength < 10) || (!$isDesktop && $sliderLength < 3)) {
          return 'center';
        }
        return '';
      }};
      margin: 0;
      padding: 0;
      width: 245px;

      @media ${media.greaterThan('lg')} {
        gap: 18px;
        width: 936px;
      }
      .swiper-slide {
        margin: 0;
        max-width: 76px;

        @media ${media.greaterThan('lg')} {
          width: 80px;
        }
      }
    }
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 20px;
  `,
  ArrowButton: styled.button`
    ${slideToRightArrowsAnimation}
  `,
};
interface ArrowButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  showArrows: boolean;
}
function ArrowButton({ showArrows, children, ...props }: ArrowButtonProps) {
  if (!showArrows) return null;
  return (
    <S.ArrowButton type="button" {...props}>
      {children}
    </S.ArrowButton>
  );
}
function Header({ header }: { header: SelectorSliderProps['header'] }) {
  return (
    <S.Header {...header} defaultTextColor={colours.WHITE} defaultTextSize={TypographyStylesType.CTA_LARGE_SEMIBOLD}>
      {getLocalizedValue(header?.text)}
    </S.Header>
  );
}

function SliderList({ sliderValues }: { sliderValues: SelectorSliderProps['sliderValues'] }) {
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const showArrows = sliderValues && isDesktop ? sliderValues.length > 10 : (sliderValues?.length || 0) > 3;
  const slidePerView = isDesktop ? 10 : 3;
  const slidesPerView = showArrows ? slidePerView : sliderValues?.length || 0;

  return (
    <S.SwiperWrapper
      className="swiper-container"
      key={isDesktop ? 'desktop-selector-slider' : 'mobile-selector-slider'}
    >
      <ArrowButton
        aria-label="Show Previous Slide Item"
        type="button"
        className="selector-slider-prev"
        showArrows={showArrows}
      >
        <ArrowCircleLeft viewBox="-1 -1 18.5 19.5" />
      </ArrowButton>
      <S.Swiper
        enabled={!isDesktop}
        $isDesktop={isDesktop}
        $sliderLength={sliderValues?.length || 0}
        modules={[Navigation]}
        slidesPerView={slidesPerView}
        navigation={{ prevEl: '.selector-slider-prev', nextEl: '.selector-slider-next' }}
        breakpoints={{
          1024: {
            touchRatio: 0,
          },
        }}
      >
        {sliderValues?.map(({ values, link }) => (
          <SwiperSlide key={values} className="swiper-slide">
            <S.SliderItem>
              <LinkPartial {...link}>{values}</LinkPartial>
            </S.SliderItem>
          </SwiperSlide>
        ))}
      </S.Swiper>

      <ArrowButton
        aria-label="ShowNext Slide Item"
        type="button"
        className="selector-slider-next"
        showArrows={showArrows}
      >
        <ArrowCircleRight viewBox="-1 -1 18.5 19.5" />
      </ArrowButton>
    </S.SwiperWrapper>
  );
}

export function SelectorSlider({ header, sliderValues }: SelectorSliderProps) {
  return (
    <S.Container>
      <Header header={header} />
      <SliderList sliderValues={sliderValues} />
    </S.Container>
  );
}

export default SelectorSlider;
